* {
  box-sizing: border-box;
}

body {
  background-color: var(--primary-background-color);
  font-family: var(--primary-font-family);
  color: var(--primary-text-color);
}

.App {
  display: flex;
  min-height: 100vh;
  min-height: 100dvh;
  flex-direction: column;
}

.inner {
  width: 100%;
  max-width: 75rem;
  margin: 0 auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.title {
  font-family: var(--decorative-font-family);
  font-size: 1.5rem;
  a {
    color: var(--primary-link-color);
    text-decoration: none;
  }
}

main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: relative;
}

.attribution {
  line-height: 1.6;
}
