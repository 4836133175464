.timezone-picker.timezone-picker {
  min-width: 250px;
  font-size: 1rem;

  .timezone-picker-select__control {
    background: none;
    border: 1px solid var(--secondary-button-border-color);
    border-radius: var(--secondary-button-border-radius);
    color: var(--secondary-button-text-color);
    text-align: center;

    &::placeholder {
      color: var(--secondary-button-text-color);
    }
  }
  .timezone-picker-select__single-value,
  .timezone-picker-select__input-container {
    color: var(--secondary-button-text-color);
  }
  .timezone-picker-select__menu {
    background-color: var(--secondary-button-background-color);
    color: var(--secondary-button-text-color);
  }
  .timezone-picker-select__option--is-focused,
  .timezone-picker-select__option--is-selected {
    background-color: var(--secondary-button-text-color);
    color: var(--secondary-button-background-color);
  }
  .timezone-picker-select__dropdown-indicator:hover {
    color: var(--secondary-button-text-color);
  }
}
