footer {
  padding-top: 1rem;
  .cta {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    a {
      color: var(--primary-link-color);;
      margin-top: 1rem;
    }
    .github {
      margin-left: 5px;
      img {
        width: 20px;
        margin-bottom: -4px;
      }
    }
  }
}

@media screen and (min-width: 700px) {
  footer .cta {
    flex-direction: row
  }
}
