main p {
  text-align: center;
  margin: 0;
}

.time-wrapper {
  input {
    background: none;
    color: var(--primary-text-color);
    font-size: 2rem;
    opacity: 0.8;
    font-weight: bold;
    border: none;
    text-decoration: underline;
    padding: 1rem;
    text-align: center;
    cursor: pointer;
    max-width: 100%;
    width: 100%;
  }
}

.stamp-generate {
  color: var(--primary-button-text-color);
  cursor: pointer;
  box-shadow: none;
  font-size: 1.5rem;
  padding: 1rem;
  border: none;
  background-color: var(--primary-button-background-color);
  border-radius: var(--primary-button-border-radius);
  font-family: var(--decorative-font-family);
  margin: 2rem 1rem 0;

  &:focus,
  &:hover {
    background-color: var(--focused-primary-button-background-color);
  }
}

.stamp-link-wrapper {
  display: flex;
  flex-direction: column;

  .arrow-up {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid var(--secondary-button-border-color);
    margin: 0.5rem auto 0;
  }

  .stamp-link {
    display: flex;
    flex-direction: row;
  }

  a {
    color: var(--secondary-button-text-color);
    text-decoration: none;
    border: 1px solid var(--secondary-button-border-color);
    border-right: none;
    border-top-left-radius: var(--secondary-button-border-radius);
    border-bottom-left-radius: var(--secondary-button-border-radius);
    padding: 0.5rem;
    flex: 1 1;
  }

  .copy-button {
    flex: 0 1;
    background: var(--secondary-button-background-color);
    border: 1px solid var(--secondary-button-border-color);
    border-top-right-radius: var(--secondary-button-border-radius);
    border-bottom-right-radius: var(--secondary-button-border-radius);
    color: var(--secondary-button-text-color);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: var(--focused-secondary-button-background-color);
      color: var(--focused-secondary-button-text-color);
    }

    svg {
      fill: currentColor;
    }
  }

}

