.subhead {
}

.time-hour {
  text-align: center;
  font-size: 60px;
  opacity: 0.8;
  font-weight: bold;
}

.time-day {
  text-align: center;
  font-size: 2rem;
  opacity: 0.8;
  margin: 1rem;
}

.time-utc {
  text-align: center;
  margin: 1rem;
}

.display.arrow-up {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid var(--secondary-button-border-color);
  margin: 0.5rem auto 0;
}

.change-format {
  color: var(--primary-button-text-color);
  box-shadow: none;
  font-size: 1rem;
  padding: 0.4rem;
  border: none;
  background-color: var(--primary-button-background-color);
  border-radius: var(--primary-button-border-radius);
  cursor: pointer;
  font-family: var(--primary-button-font-family);
  margin: 0;
  position: absolute;
  top: -1.5rem;
  right: 0;

  &:hover,
  &:focus {
    background-color: var(--focused-primary-button-background-color);
  }
}

.atcb-button.atcb-button {
  z-index: 0;
}

@media screen and (min-width: 720px) {
/*  font-size: 120px; */
}
